import React from "react";
import { DivButton, TextButton, Icon, IconButton, HoverableTooltip } from "UI";
import { classnames } from "Utils";
import DummyAppLogo from "Assets/images/appStreaming/dummy-app-logo.png";
import { useHistory } from "react-router-dom";
import routes from "Constants/Route.constants";
import { useSelector } from "react-redux";
import DropdownMenu from "UI/DropdownMenu/DropdownMenu.component";
import { APPLICATION_STATUS, BG_STYLE_BY_APP_FRIENDLY_STATUS, VENDOR_TYPES } from "Constants/AppStreaming.constants";
import "./AppCard.styles.scss";
import { OS } from "Constants/global.constants";

const AppCard = ({
  app,
  appLogo,
  translate,
  onConfigure,
  onShare,
  overrideContent,
  transparent,
  setShowDeleteAppConfirmationModal,
  setShowApplicationVersionsModal,
  setShowAppBudgetModal,
  selectedApp,
  setSelectedApp,
  showApplicationOnboardingModal,
  setShowBundledAppListModal,
  setShowExecutableHelpersModal,
  className: classNameFromProps,
}) => {
  const containerRef = React.useRef(null);
  const imgRef = React.useRef(null);
  const bundleButtonRef = React.useRef(null);
  const history = useHistory();

  const vendorType = useSelector((state) => state.appStreaming.vendorType);
  const appVersioningButtonEnabled = vendorType === VENDOR_TYPES.enterprise || vendorType === VENDOR_TYPES.pro;
  const appBudgetButtonEnabled = vendorType === VENDOR_TYPES.enterprise;
  const { highlightClass } = app.attributes;
  const {
    budget_limit_reached: budgetLimitReached,
    friendly_status: friendlyStatus,
    is_bundled_app: isBundledApp,
  } = app?.attributes || {};
  const packaging = friendlyStatus === APPLICATION_STATUS.PACKAGING;
  const isLinux = app?.attributes?.os === OS.linux;

  const menuItems = [
    {
      key: "createStream",
      label: translate("appStreamingDashboard.app.menuPopUp.createStreams"),
      onClick: () => {
        history.push({
          pathname: routes.appStreamingStreams,
          selectedApp,
        });
      },
      disabled: packaging,
    },
    {
      key: "appBudget",
      label: (
        <>
          {translate("appStreamingDashboard.app.menuPopUp.appBudget")}{" "}
          <span className="enterprise-badge">Enterprise</span>
        </>
      ),
      onClick: () => {
        setShowAppBudgetModal(true);
      },
      disabled: !appBudgetButtonEnabled,
    },
    {
      key: "multipleExecutables",
      label: (
        <>
          {translate("appStreamingDashboard.app.menuPopUp.multipleExecutables")}{" "}
          <span className="enterprise-badge">Enterprise</span>
        </>
      ),
      onClick: () => {
        setShowExecutableHelpersModal(true);
      },
      disabled: !appBudgetButtonEnabled || isLinux || isBundledApp,
    },
    {
      key: "versions",
      label: (
        <>
          {translate("appStreamingDashboard.app.menuPopUp.versions")}
          <span className="pro-badge">Pro</span>
        </>
      ),
      onClick: () => {
        setShowApplicationVersionsModal(true);
      },
      disabled: !appVersioningButtonEnabled || app?.attributes?.is_bundled_app,
    },
    {
      key: "delete",
      label: translate("appStreamingDashboard.app.menuPopUp.delete"),
      onClick: () => {
        setShowDeleteAppConfirmationModal(true);
      },
      disabled: packaging,
    },
  ];

  return (
    <DivButton
      className={classnames(["app-card-container", transparent && "transparent", classNameFromProps, highlightClass])}
      onClick={(e) => {
        if (!showApplicationOnboardingModal) {
          if (
            containerRef.current &&
            containerRef.current.contains(e.target) &&
            !bundleButtonRef?.current?.contains(e.target) &&
            selectedApp.id === app.id
          ) {
            setSelectedApp({});
            return;
          }
          setSelectedApp(app);
        }
      }}
      divRef={containerRef}
    >
      <div className={classnames(["app-logo-container", !app && "centered"])}>
        {app && (
          <div className="top-line">
            <div className="status">
              <div className="status-circle" style={BG_STYLE_BY_APP_FRIENDLY_STATUS[app.attributes.friendly_status]} />
              {translate(`appStreamingDashboard.appList.appFriendlyStatus.${app.attributes.friendly_status}`)}
              {budgetLimitReached && (
                <HoverableTooltip content="Application Budget Reached" sideOffset={10}>
                  <Icon name="warning" className="info-icon" color="red" />
                </HoverableTooltip>
              )}
              {app?.attributes?.optimized && (
                <HoverableTooltip content="Optimized" className="optimization-badge">
                  <Icon name="check" color="aqua-main" className="info-icon" />
                </HoverableTooltip>
              )}
            </div>
            {!showApplicationOnboardingModal ? (
              <DropdownMenu
                menuItems={menuItems}
                side="left"
                align="start"
                className="application-card-pop-up-menu"
                onOpen={() => {
                  setSelectedApp(app);
                }}
                disabled={showApplicationOnboardingModal}
              >
                <Icon name="more" className="selected-file-actions" color="white" />
              </DropdownMenu>
            ) : (
              <Icon name="more" className="selected-file-actions" color="white" />
            )}
          </div>
        )}
        <img ref={imgRef} src={appLogo || DummyAppLogo} />
        {isLinux && <Icon name="linux" className="os-logo" key={imgRef} />}

        {isBundledApp && (
          <IconButton
            iconRef={bundleButtonRef}
            name="stack"
            color="white-stroke"
            className="bundled-app-icon"
            key={imgRef}
            onClick={() => {
              setSelectedApp(app);
              setShowBundledAppListModal(true);
            }}
          />
        )}
      </div>
      <div className="content">
        {overrideContent || (
          <>
            <h5>{app.attributes.name}</h5>
            <div className="action-buttons-container">
              <TextButton
                text={translate("appStreamingDashboard.app.actions.share")}
                color="aqua-dark"
                onClick={(e) => {
                  if (!showApplicationOnboardingModal) {
                    e.stopPropagation();
                    onShare();
                  }
                }}
                iconPosition="left"
                icon="share"
                disabled={packaging}
              />
              <div className="vertical-divider" />
              <TextButton
                text={translate("appStreamingDashboard.app.actions.configure")}
                color="aqua-dark"
                onClick={(e) => {
                  if (!showApplicationOnboardingModal) {
                    e.stopPropagation();
                    onConfigure(app);
                  }
                }}
                iconPosition="left"
                icon="dashboard"
                disabled={packaging}
              />
            </div>
          </>
        )}
      </div>
    </DivButton>
  );
};

export default AppCard;
