import { all, debounce, put } from "redux-saga/effects";
import { Tracker } from "Utils";
import apiGenerator from "Utils/Helpers/api.helpers";
import { removeItemFromLocalStorage, setAccessTokenInLocalStorage } from "Utils/Helpers/storage.helpers";
import { getStatusCodeFamily, apiErrorHandler } from "Utils/Helpers/saga.helpers";
import { LOCAL_STORAGE } from "Constants/global.constants";
import * as AppStreamingConstants from "Constants/AppStreaming.constants";
import * as OrganizationConstants from "Constants/Organization.constants";
import * as LoginConstants from "Constants/Authentication/Login.constants";
import * as RegisterConstants from "../Constants/Authentication/Register.constants";

import { API_ENDPOINTS, STATUS_TYPE, APP_STREAMING_API_ENDPOINTS } from "../Constants/api.constants";

function* registerAPISaga(action) {
  const api = apiGenerator("post")(API_ENDPOINTS.REGISTER, action.payload);
  try {
    const response = yield api;
    const { user, token } = response.data;
    const { id } = user;
    const { scope } = user.attributes;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      Tracker.event({ type: "register", action: "success", userUid: id, scope });

      window.fbq("track", "CompleteRegistration", {
        status: "success",
        userUid: id,
        scope,
      });

      removeItemFromLocalStorage(LOCAL_STORAGE.amzMarketplaceToken);

      if (token) {
        setAccessTokenInLocalStorage(token);

        yield put({
          type: LoginConstants.LOGIN_API_SUCCESS,
          payload: { accessToken: token, scope },
        });
      }

      yield put({
        type: RegisterConstants.REGISTER_API_SUCCESS,
        payload: { accessToken: token, scope },
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put({
      type: RegisterConstants.REGISTER_API_FAILURE,
      payload: error,
    });
  }
}

function* appStreamingRegisterAPISaga(action) {
  const api = apiGenerator("post")(APP_STREAMING_API_ENDPOINTS.REGISTER, action.payload);
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      const { user } = response.data;
      const { id } = user || {};

      Tracker.event({ type: "register", action: "success" });
      window.fbq("track", "CompleteRegistration", {
        status: "success",
        userUid: id,
      });

      yield put({
        type: AppStreamingConstants.APP_STREAMING_REGISTER_API_SUCCESS,
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put({
      type: AppStreamingConstants.APP_STREAMING_REGISTER_API_FAILURE,
      payload: error,
    });
  }
}

function* organizationRegisterAPI(action) {
  const api = apiGenerator("post")(API_ENDPOINTS.ORGANIZATIONS_REGISTER, action.payload);
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      Tracker.event({ type: "register", action: "success" });
      const { user } = response.data;
      const { id } = user || {};

      window.fbq("track", "CompleteRegistration", {
        status: "success",
        userUid: id,
      });

      yield put({
        type: OrganizationConstants.ORGANIZATION_REGISTER_API_SUCCESS,
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put({
      type: OrganizationConstants.ORGANIZATION_REGISTER_API_FAILURE,
      payload: error,
    });
  }
}

const DEBOUNCE_DELAY = 300; // to prevent multiple API calls

export default function* root() {
  yield all([
    debounce(DEBOUNCE_DELAY, RegisterConstants.REGISTER_API_PENDING, registerAPISaga),
    debounce(DEBOUNCE_DELAY, AppStreamingConstants.APP_STREAMING_REGISTER_API_PENDING, appStreamingRegisterAPISaga),
    debounce(DEBOUNCE_DELAY, OrganizationConstants.ORGANIZATION_REGISTER_API_PENDING, organizationRegisterAPI),
  ]);
}
