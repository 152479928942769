import React from "react";
import { Link } from "react-router-dom";
import routes from "Constants/Route.constants";
import { DivButton, Icon } from "UI";
import { classnames } from "Utils";

const menuItemClassName = (path, checkValue, disabled) =>
  `menu-item ${path === checkValue ? "active" : ""} ${disabled ? "disabled" : ""}`;

const isActiveMenuItem = (path, checkValue) => path === checkValue;

const AppStreamingMenuItems = (props) => {
  const { path, setShowMenu, mobile, account, appsMenu } = props;
  const balance = parseFloat(account.account && account.account.attributes.balance) || 0;
  const { vendor_admin: vendorAdmin, vendor_member: vendorMember } = account?.account?.attributes || {};

  const disabled = !vendorAdmin && !vendorMember;

  const onLinkClick = () => {
    if (setShowMenu) {
      setShowMenu(false);
    }
  };

  return (
    <div className="menu-wrapper">
      {mobile && balance > 0 && (
        <div className="menu-balance">
          Your Balance
          <p>{`${balance.toFixed(2)} USD`}</p>
          <div className="divider" />
        </div>
      )}
      <Link
        to={routes.appStreamingHome}
        className={classnames([
          menuItemClassName(path, routes.appStreamingHome, disabled),
          "app-streaming-menu-item",
          "applications",
        ])}
        onClick={onLinkClick}
      >
        <Icon
          name="applications"
          className="menu-item-icon"
          color={`${isActiveMenuItem(path, routes.appStreamingHome, disabled) ? "purple-main" : "gray-3"}`}
        />
        Applications
      </Link>
      <Link
        to={routes.appStreamingStreams}
        className={classnames([
          menuItemClassName(path, routes.appStreamingStreams, disabled),
          "app-streaming-menu-item",
          "streams",
        ])}
        onClick={onLinkClick}
      >
        <Icon
          name="streams"
          className="menu-item-icon"
          color={`${isActiveMenuItem(path, routes.appStreamingStreams, disabled) ? "purple-main" : "gray-3"}`}
        />
        Streams
      </Link>
      <Link
        to={routes.appStreamingBilling}
        className={classnames([
          menuItemClassName(path, routes.appStreamingBilling, disabled),
          "app-streaming-menu-item",
          "billing",
        ])}
        onClick={onLinkClick}
      >
        <Icon
          name="payment"
          className="menu-item-icon"
          color={`${isActiveMenuItem(path, routes.appStreamingBilling) ? "purple-main" : "gray-3"}`}
        />
        Billing
      </Link>
      <Link
        to={routes.appStreamingUsers}
        className={classnames([
          menuItemClassName(path, routes.appStreamingUsers, disabled),
          "app-streaming-menu-item",
          "stats",
        ])}
        onClick={onLinkClick}
      >
        <Icon
          name="stats"
          className="menu-item-icon"
          color={`${isActiveMenuItem(path, routes.appStreamingUsers) ? "purple-main" : "gray-3"}`}
        />
        Stats
      </Link>
      <Link
        to={routes.appStreamingSettings}
        className={classnames([
          menuItemClassName(path, routes.appStreamingSettings, disabled),
          "app-streaming-menu-item",
          "settings",
        ])}
        onClick={onLinkClick}
      >
        <Icon
          name="stream-settings"
          className="menu-item-icon"
          color={`${isActiveMenuItem(path, routes.appStreamingSettings) ? "purple-main" : "gray-3"}`}
        />
        Settings
      </Link>
      <a
        className="menu-item app-streaming-menu-item"
        href="https://docs.vagon.io/streams"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon name="api" className="menu-item-icon" color="gray-3" />
        API Docs
      </a>
      <div className="menu-item-bottom">
        {appsMenu({ appStreaming: true })}
        <Link
          to={routes.login}
          className={classnames([menuItemClassName(path, routes.login), "app-streaming-menu-item"])}
          onClick={() => {
            props.logoutAPI(props.account.accessToken);
            onLinkClick();
          }}
        >
          Sign out
        </Link>
        {!mobile && <div className="divider" />}
        <DivButton className="menu-item support app-streaming-menu-item" onClick={() => window.Intercom("show")}>
          Help / Support
        </DivButton>
      </div>
    </div>
  );
};

export default AppStreamingMenuItems;
