import { REGISTER_API_PENDING, REGISTER_CLEAR } from "Constants/Authentication/Register.constants";
import { APP_STREAMING_REGISTER_API_PENDING } from "Constants/AppStreaming.constants";
import { ORGANIZATION_REGISTER_API_PENDING } from "Constants/Organization.constants";
import { LOCAL_STORAGE, SESSION_STORAGE } from "Constants/global.constants";
import { getItemFromLocalStorage, getItemFromSessionStorage } from "Utils/Helpers/storage.helpers";

export const registerClear = () => ({
  type: REGISTER_CLEAR,
});

export const registerAPI = (registerForm) => {
  const source = getItemFromSessionStorage(SESSION_STORAGE.source) || null;

  const payload = {
    name: registerForm.name,
    email: registerForm.email.toLowerCase(),
    promo_code: registerForm.promoCode,
    referrer_code: registerForm.referralCode,
    password: registerForm.password,
    password_confirmation: registerForm.password,
    recaptcha_response: registerForm.recaptchaResponse,
    team_name: registerForm.companyName,
    fingerprint: registerForm.visitorData.visitorId,
    fingerprint_response: registerForm.visitorData,
    initial_scope: registerForm.initialScope,
    provider: registerForm.provider,
    id_token: registerForm.assertion,
    source,
    aws_marketplace_token: getItemFromLocalStorage(LOCAL_STORAGE.amzMarketplaceToken),
  };
  return {
    type: REGISTER_API_PENDING,
    payload,
  };
};

export const appStreamingRegisterClear = () => ({
  type: REGISTER_CLEAR,
});

export const appStreamingRegisterAPI = (appStreamingRegisterForm) => {
  const referrer = appStreamingRegisterForm.referrer?.length < 20 ? appStreamingRegisterForm.referrer : null;

  const payload = {
    email: appStreamingRegisterForm.email.toLowerCase(),
    password: appStreamingRegisterForm.password,
    password_confirmation: appStreamingRegisterForm.password,
    name: appStreamingRegisterForm.name,
    vendor_name: appStreamingRegisterForm.companyName,
    recaptcha_response: appStreamingRegisterForm.recaptchaResponse,
    fingerprint: appStreamingRegisterForm.visitorData.visitorId,
    fingerprint_response: appStreamingRegisterForm.visitorData,
    referrer,
    aws_marketplace_token: getItemFromLocalStorage(LOCAL_STORAGE.amzMarketplaceToken),
  };
  return {
    type: APP_STREAMING_REGISTER_API_PENDING,
    payload,
  };
};

export const organizationRegisterClear = () => ({
  type: REGISTER_CLEAR,
});

export const organizationRegisterAPI = (form) => {
  const referrer = form.referrer?.length < 20 ? form.referrer : null;

  const payload = {
    email: form.email.toLowerCase(),
    password: form.password,
    password_confirmation: form.password,
    name: form.name,
    organization_name: form.companyName,
    recaptcha_response: form.recaptchaResponse,
    fingerprint: form.visitorData.visitorId,
    fingerprint_response: form.visitorData,
    referrer,
    aws_marketplace_token: getItemFromLocalStorage(LOCAL_STORAGE.amzMarketplaceToken),
  };
  return {
    type: ORGANIZATION_REGISTER_API_PENDING,
    payload,
  };
};
