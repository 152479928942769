import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";

import { TRANSACTION_TYPES } from "Constants/global.constants";
import { DashboardSubScreenLayout, PaymentMethods, AddBalanceModal, WorkstationUsages, Loader } from "Utils";
import { TextButton } from "UI";
import { convertDateFormat, convertToSelectedTimeZone, normalizeCredits } from "Utils/Helpers/functions.helpers";
import NothingToShow from "Assets/images/vagon-nothing.png";
import "react-circular-progressbar/dist/styles.css";

import BillingDetails from "Utils/Components/BillingDetails/BillingDetails.component";
import NetworkUsageLimit from "Utils/Components/NetworkUsageLimit/NetworkUsageLimit.component";
import useCustomInfiniteQuery from "Utils/Hooks/useCustomInfiniteQuery";
import { API_ENDPOINTS } from "Constants/api.constants";
import "./Billing.styles.scss";

const HEADERS = ["Date", "Invoice Number", "Description", "Amount"];

const KEYS = ["date", "invoiceNumber", "description", "totalAmount"];

const TRANSACTION_HEADERS = ["Date", "Transaction", "Description", "Amount"];

const TRANSACTION_KEYS = ["date", "invoiceNumber", "description", "amount"];

const PAGES = {
  payments: 0,
  transactions: 1,
  networkUsage: 2,
};

const sortItems = (items) =>
  items.sort((a, b) => {
    if (!b) return 1;
    return b.amount - a.amount;
  });

const sumDiscountAndSubscription = (items) => {
  let discount = 0;
  let subscription = 0;
  let editedItems = [];
  items.forEach((item) => {
    if (item.description === "Subscription") {
      subscription = item.amount;
    } else if (item.description === "Discount") {
      discount = item.amount;
    }
  });
  if (subscription !== 0 && discount !== 0) {
    editedItems = items.filter((item) => item.description !== "Subscription" && item.description !== "Discount");

    editedItems.push({
      id: 0,
      description: "Subscription",
      amount: (parseFloat(subscription) + parseFloat(discount)).toFixed(2),
    });
    return editedItems;
  }
  return items;
};

const getBillingItemObjects = (billingItems) => {
  return _.map(billingItems, (billingItem) => ({
    id: billingItem.id,
    description: billingItem.attributes.description,
    amount: billingItem.attributes.amount,
  }));
};

const getBillingDetailsComponentObjects = (data, userTimeZone) => {
  const filteredBilling = _.filter(data, (payment) => payment.attributes.amount !== "0.0");
  return _.map(filteredBilling, (payment) => {
    const billingItems = getBillingItemObjects(payment.attributes.items);
    const createdAt = payment.attributes.created_at;
    return {
      id: payment.id,
      date: createdAt
        ? convertToSelectedTimeZone(
            `${convertDateFormat(createdAt.substring(0, 10))} ${createdAt.substring(
              createdAt.indexOf("T") + 1,
              createdAt.indexOf("T") + 6,
            )}`,
            userTimeZone,
          )
        : "",
      invoiceNumber: payment.attributes.invoice_number,
      description: payment.attributes.description,
      totalAmount: `$ ${payment.attributes.total_amount}`,
      items: sortItems(sumDiscountAndSubscription(billingItems)),
    };
  });
};

const getTransactionDetailsComponentObjects = (data) => {
  const filteredBilling = _.filter(data, (payment) => payment.attributes.amount !== "0.0");

  return _.map(filteredBilling, (payment) => {
    const positiveAmount = payment.attributes.amount > 0;
    return {
      id: payment.id,
      date: moment(payment.attributes.day).format("DD/MM/YYYY"),
      invoiceNumber: `VGN-${`00000${payment.id}`.slice(-5)}`,
      description: TRANSACTION_TYPES[payment.attributes.transaction_type],
      amount: `${positiveAmount ? "+" : "-"} $ ${Math.abs(payment.attributes.amount).toFixed(2)}`,
    };
  });
};

const getWorkstationsUsageComponentObjects = (data) => {
  return _.map(data, (dataItem) => {
    return {
      attributes: {
        machine_type: dataItem.attributes.details,
        minutes: dataItem.attributes.possible_usage_with_balance,
      },
    };
  });
};

const BillingComponent = (props) => {
  const { account, subscriptions, workstations, translate } = props;

  const [showAddBalanceModal, setShowAddBalanceModal] = useState(false);

  const [activePage, setActivePage] = useState(PAGES.payments);
  const { time_zone: userTimeZone } = account.account.attributes;
  const balance = parseFloat(account.account.attributes.balance || account.account.attributes.debt).toFixed(2);
  const personalBalance = parseFloat(account.account.attributes.blocked_balance).toFixed(2);
  const credit = normalizeCredits(account.account.attributes.credit);
  const hasSubscription = account?.account?.attributes?.subscriptions?.length > 0;
  const remainingNetworkUsage = subscriptions?.subscription?.attributes?.remaining_network_usage;
  const networkUsage = subscriptions?.subscription?.attributes?.plan?.attributes?.network?.free_usage;
  const isComputerCreated = workstations?.workstations?.length;
  const userFromAwsMarketplace = account.account.attributes?.disable_new_payment_method; // Users registered from aws marketplace

  const hideHistory =
    !(!account.isTeamAccount || hasSubscription || personalBalance > 0) &&
    account.transactionHistoryCTX.data?.length === 0 &&
    account.paymentHistoryCTX.data?.length === 0;

  const {
    data: paymentHistory,
    fetchNextPage: fetchNextPagePayments,
    isFetchingNextPage: isFetchingNextPagePayments,
    hasNextPage: hasNextPagePayments,
    isLoading: paymentHistoryLoading,
  } = useCustomInfiniteQuery({
    endpoint: API_ENDPOINTS.PAYMENT_HISTORY,
    resource: "payments",
  });

  const {
    data: transactionHistory,
    fetchNextPage: fetchNextPageTransactions,
    isFetchingNextPage: isFetchingNextPageTransactions,
    hasNextPage: hasNextPageTransactions,
    isLoading: transactionHistoryLoading,
  } = useCustomInfiniteQuery({
    endpoint: API_ENDPOINTS.TRANSACTION_HISTORY,
    resource: "transactions",
  });

  const TeamAccountView = () => {
    if (hideHistory) {
      return (
        <div className="vg-usage-empty">
          <img src={NothingToShow} alt="Nothing to show" className="stripe-image" />
          <h1>{translate("teamUsageHistory.nothingToShow")}</h1>
          <p>{translate("teamUsageHistory.useVagon")}</p>
        </div>
      );
    } else if (account.isTeamAccount && !hasSubscription && parseInt(personalBalance, 10) === 0) {
      return null;
    }

    return (
      <div className="transactions-and-payment-container">
        {hasSubscription && (
          <div className="usages-container">
            <div className="payment-methods-header">{translate("billing.usages.header")}</div>
            {!workstations.getWorkstationMachineTypesCTX.data?.machine_types ? (
              <Loader />
            ) : (
              <>
                <WorkstationUsages
                  usages={getWorkstationsUsageComponentObjects(
                    workstations.getWorkstationMachineTypesCTX.data?.machine_types,
                  )}
                />
                <p className="remaining-credit">{translate("billing.remainingCredit", { credit })}</p>
              </>
            )}
          </div>
        )}
        {personalBalance > 0 && (
          <div className="transactions-container team-member">
            <div className="header">{translate("billing.teamMember.balance.header")}</div>
            <div className="balance-information-container">
              <div className="balance-information-content">
                <div className="balance-header">{translate("billing.teamMember.balance.title")}</div>
                <div className="balance-amount">{personalBalance} USD</div>
              </div>
            </div>
            <div className="description">
              <div className="divider" />
              {translate("billing.teamMember.balance.description")}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("billing.title")}
      headerDescription={translate("billing.description")}
      showBalance={false}
      waitFor={[account.transactionHistoryCTX.status, account.paymentHistoryCTX.status]}
      translate={translate}
    >
      <>
        {account.isTeamAccount ? (
          TeamAccountView()
        ) : (
          <div className="transactions-and-payment-container">
            <div className="transactions-container">
              <div className="header">{translate("billing.balance.header")}</div>
              <div className="balance-information-container">
                <div className="balance-information-content">
                  <div className="balance-header">{translate("billing.balance.title")}</div>
                  <div className="balance-amount">{balance} USD</div>
                </div>

                {!userFromAwsMarketplace && (
                  <a className="balance-action" href="#add-balance" onClick={() => setShowAddBalanceModal(true)}>
                    {translate("billing.balance.buttonText")}
                  </a>
                )}
              </div>
              <div className="description">
                <div className="divider" />
                {translate("billing.balance.description")}
              </div>
            </div>
            <div className="payment-container">
              <div className="payment-methods-header">{translate("billing.paymentMethods.header")}</div>
              <PaymentMethods translate={translate} />
            </div>
          </div>
        )}
        {/* Do not show history if team account and not used yet */}
        {!hideHistory && (
          <div className="billing-history-container">
            <div className="billing-history-titles">
              <TextButton
                color={activePage === PAGES.payments ? "purple" : ""}
                disabled={activePage === PAGES.payments}
                text={translate("billing.payments")}
                onClick={() => setActivePage(PAGES.payments)}
              />
              <TextButton
                color={activePage === PAGES.transactions ? "purple" : ""}
                disabled={activePage === PAGES.transactions}
                text={translate("billing.transactions")}
                onClick={() => setActivePage(PAGES.transactions)}
              />
              {!account.isTeamAccount && hasSubscription && isComputerCreated && (
                <TextButton
                  color={activePage === PAGES.networkUsage ? "purple" : ""}
                  disabled={activePage === PAGES.networkUsage}
                  text={translate("billing.networkUsage.header")}
                  onClick={() => setActivePage(PAGES.networkUsage)}
                />
              )}
            </div>
            {activePage === PAGES.payments && (
              <BillingDetails
                loading={paymentHistoryLoading}
                headers={HEADERS}
                objects={getBillingDetailsComponentObjects(paymentHistory, userTimeZone)}
                fetchNextPage={fetchNextPagePayments}
                isFetchingNextPage={isFetchingNextPagePayments}
                hasNextPage={hasNextPagePayments}
                keys={KEYS}
              />
            )}
            {activePage === PAGES.transactions && (
              <BillingDetails
                loading={transactionHistoryLoading}
                headers={TRANSACTION_HEADERS}
                objects={getTransactionDetailsComponentObjects(transactionHistory)}
                fetchNextPage={fetchNextPageTransactions}
                isFetchingNextPage={isFetchingNextPageTransactions}
                hasNextPage={hasNextPageTransactions}
                keys={TRANSACTION_KEYS}
                hideDetails
              />
            )}
            {activePage === PAGES.networkUsage && (
              <NetworkUsageLimit
                remainingNetworkUsage={remainingNetworkUsage}
                networkUsage={networkUsage}
                translate={translate}
                personal
              />
            )}
          </div>
        )}
        {showAddBalanceModal && (
          <AddBalanceModal
            setShowAddBalanceModal={setShowAddBalanceModal}
            accessToken={account.accessToken}
            translate={translate}
          />
        )}
      </>
    </DashboardSubScreenLayout>
  );
};

export default BillingComponent;
