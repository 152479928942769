import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";

import { Button, RadioInput, TopLoader } from "UI";
import {
  Loader,
  PaymentMethodForm,
  PaymentMethods,
  PaymentDetails,
  Payment3DSecureModal,
  isMobile,
  classnames,
  getItemFromLocalStorage,
} from "Utils";

import { LOCAL_STORAGE, PAYMENT_TYPE, REQUEST_STATUS } from "Constants/global.constants";
import PoweredByStripe from "Assets/images/stripe/powered_by_stripe.png";
import VagonGift from "Assets/images/referral/vagon-gift.png";
// import Paypal from "Assets/images/cardServices/paypal-alternative.svg";

import "./CreateComputerPayment.styles.scss";

const canUserCompleteSubscription = (amount, hasPreferredPaymentMethod, creditCardRequired) => {
  if (parseFloat(amount) === 0 && !creditCardRequired) {
    return true;
  }
  if (!hasPreferredPaymentMethod) {
    return false;
  }
  return true;
};

const CreateComputerPayment = (props) => {
  const {
    account,
    payment,
    referral,
    onComplete,
    workstations,
    subscriptions,
    getPaymentClientSecretAPI,
    createUserPaymentMethodAPI,
    translate,
  } = props;

  const [showPayment3DSecure, setShowPayment3DSecure] = useState(false);
  const [isPaymentPermissionGiven, setIsPaymentPermissionGiven] = useState(false);
  const [isPaymentMethodFormComplete, setIsPaymentMethodFormComplete] = useState(false);
  const [isCardInputComplete, setIsCardInputComplete] = useState(false);

  const [paymentType, setPaymentType] = useState(PAYMENT_TYPE.CREDIT_CARD);
  const [pending, setPending] = useState();
  const [errorAddPayment, setErrorAddPayment] = useState(false);
  const [errorAddPaymentEnd, setErrorAddPaymentEnd] = useState(false);
  const [error, setError] = useState(false);

  const paymentMethodFormRef = useRef(null);
  const isBalanceSelected = !!getItemFromLocalStorage(LOCAL_STORAGE.createComputerConfigs)?.balance;
  const userFromAwsMarketplace = account.account.attributes?.disable_new_payment_method; // Users registered from aws marketplace

  const {
    amount,
    extra_disk_price: extraDiskPrice,
    trial_interval: trialInterval,
    trial_enabled: trialEnabled,
    credit_card_required: creditCardRequired,
    payment_processing_fee: selectedBalancePrice,
    subscription_price: subscriptionPrice,
    balance: selectedBalance,
    rewards_enabled: referralEnabled,
  } = subscriptions.getUserSubscriptionPaymentInformationCTX.data || {};

  const workstationConfig = workstations.createWorkstationConfigCTX;
  const { disk_size: diskSize } = workstationConfig || {};

  const hasPaymentMethods = payment.getPaymentMethodsCTX.data && payment.getPaymentMethodsCTX.data.length > 0;
  const hasPreferredPaymentMethod = _.some(payment.getPaymentMethodsCTX.data, (method) => method.attributes.preferred);
  const extraDisk = diskSize - 75;

  const { has_deleted_subscription: hasDeletedSubscription, has_referrer: hasReferrer } = account.account.attributes;

  const readyToComplete =
    canUserCompleteSubscription(amount, hasPreferredPaymentMethod, creditCardRequired) ||
    paymentType === PAYMENT_TYPE.BALANCE;
  const showReferralBox = hasReferrer && referralEnabled && !hasDeletedSubscription;
  const { referreReward } = referral.getRewardsCTX.data;

  const balance = parseFloat(account.account.attributes.balance).toFixed(2);

  const subscriptionPaymentAmount = parseFloat(subscriptionPrice || 0).toFixed(2);

  const totalAmountToPay = parseFloat(amount).toFixed(2);

  const promoSubscriptionPaymentLineText = trialEnabled ? `${trialInterval} Days of Trial Plan` : "";

  const paymentLineItems = [
    // Hide payment lines if amount is null or 0
    // parseFloat returns NaN for null or undefined
    {
      key: 1,
      name: hasDeletedSubscription ? "Monthly Plan" : promoSubscriptionPaymentLineText,
      amount: subscriptionPaymentAmount,
    },
    {
      key: 2,
      name: `${extraDisk} GB Additional Storage`,
      amount: parseFloat(extraDiskPrice).toFixed(2),
      hide: !(parseFloat(extraDiskPrice) > 0),
    },
    {
      key: 3,
      name: translate("paymentDetails.balance"),
      amount: parseFloat(selectedBalance).toFixed(2),
      hide: !(parseFloat(selectedBalance) > 0),
    },
    {
      key: 4,
      name: translate("paymentDetails.processingFee"),
      amount: selectedBalancePrice && parseFloat(selectedBalancePrice).toFixed(2),
      hide: !(parseFloat(selectedBalancePrice) > 0),
    },
  ];

  useEffect(() => {
    if (
      payment.payment3DSecureCTX.status === REQUEST_STATUS.PENDING &&
      payment.payment3DSecureCTX.data &&
      payment.payment3DSecureCTX.data.payment_required === true
    ) {
      setShowPayment3DSecure(true);
    } else if (payment.payment3DSecureCTX.status === REQUEST_STATUS.FAILURE) {
      setShowPayment3DSecure(false);
    }
  }, [props.payment.payment3DSecureCTX]); // eslint-disable-line

  useEffect(() => {
    if (subscriptions.createUserWorkstationAsSubscriptionCTX.status === REQUEST_STATUS.FAILURE) {
      // TODO: Add other error cases
      setError(translate("payment.content.error.default"));
      setPending(false);
    }
  }, [subscriptions.createUserWorkstationAsSubscriptionCTX]); // eslint-disable-line

  useEffect(() => {
    if (payment.createUserPaymentMethodCTX.status !== REQUEST_STATUS.PENDING) {
      setPending(false);
    }
  }, [payment.createUserPaymentMethodCTX]);

  const onAddPaymentButtonClick = () => {
    if (isPaymentMethodFormComplete && paymentMethodFormRef.current) {
      if (!pending) {
        setPending(true);
        paymentMethodFormRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
      }
    }
  };

  const handleCreateWorkstation = () => {
    if (readyToComplete) {
      setPending(true);
      onComplete(paymentType);
    }
  };

  const handle3DSecureFailure = (errorMessage) => {
    setErrorAddPaymentEnd(errorMessage);
    setShowPayment3DSecure(false);
  };

  const handleEnterKeyUp = (event) => {
    event.preventDefault();
    if (event.key === "Enter") {
      setPaymentType(PAYMENT_TYPE.BALANCE);
    }
  };

  // const handleEnterKeyDown = (event) => {
  //   event.preventDefault();
  //   if (event.key === "Enter") {
  //     setSelectedPaymentMethod("paypal");
  //   }
  // };

  const paymentInformation = () => {
    const isPaymentInformationReady =
      subscriptions.getUserSubscriptionPaymentInformationCTX.status === REQUEST_STATUS.SUCCESS &&
      payment.getPaymentMethodsCTX.status === REQUEST_STATUS.SUCCESS;

    if (isPaymentInformationReady) {
      return hasPaymentMethods ? (
        <PaymentMethods translate={translate} paymentType={paymentType} setPaymentType={setPaymentType} />
      ) : (
        <>
          <h2>{translate("payment.header.paymentMethod")}</h2>
          <PaymentMethodForm
            formRef={paymentMethodFormRef}
            setError={setErrorAddPayment}
            setPending={setPending}
            setIsCardInputComplete={setIsCardInputComplete}
            setIsPaymentMethodFormComplete={setIsPaymentMethodFormComplete}
            account={account}
            payment={payment}
            createUserPaymentMethodAPI={createUserPaymentMethodAPI}
            getPaymentClientSecretAPI={getPaymentClientSecretAPI}
            translate={translate}
          />
          <RadioInput
            checked={isPaymentPermissionGiven}
            onChange={() => {
              setIsPaymentPermissionGiven(!isPaymentPermissionGiven);
            }}
            label={translate("addPaymentForm.paymentPermission")}
          />
          <Button
            text={translate("payment.action.addPaymentMethodButton")}
            errorText={errorAddPayment && (errorAddPayment?.message || translate("addPaymentForm.error.default"))}
            disabled={!isCardInputComplete || !isPaymentMethodFormComplete || !isPaymentPermissionGiven || pending}
            onClick={onAddPaymentButtonClick}
            block
          />
        </>
      );
    }
    return <Loader big centered />;
  };

  return (
    <div className="payment-container">
      {pending && <TopLoader />}
      <div className="payment-informations">
        <div className="payment-options">
          {!userFromAwsMarketplace && (
            <div className="payment-method-information information-section">{paymentInformation()}</div>
          )}

          {parseInt(balance, 10) >= parseInt(totalAmountToPay, 10) && parseInt(balance, 10) > 0 && (
            <div className="alternative-payments">
              {!isBalanceSelected && (
                <div
                  className={classnames(["pay-with-balance", paymentType === "balance" && "selected"])}
                  onClick={() => {
                    setPaymentType(PAYMENT_TYPE.BALANCE);
                  }}
                  tabIndex={0}
                  role="button"
                  onKeyUp={handleEnterKeyUp}
                >
                  <p>{translate("paymentOptions.balance")}</p>
                  <p1>${balance}</p1>
                </div>
              )}
              {/* <p>or</p>
            <div
              className={classnames(["pay-with-paypal", selectedPaymentMethod === "paypal" && "selected"])}
              onClick={() => {
                setSelectedPaymentMethod("paypal");
              }}
              tabIndex={0}
              role="button"
              onKeyUp={handleEnterKeyDown}
            >
              <p>Pay with</p>
              <img src={Paypal} />
            </div> */}
            </div>
          )}
        </div>
        <div className="summary information-section">
          <PaymentDetails
            hide={!hasPaymentMethods && isMobile}
            items={paymentLineItems}
            headerText={translate("payment.content.paymentDetailsHeader")}
          />
          <div className="stripe-info">
            <img src={PoweredByStripe} alt="Powered by Stripe" className="stripe-image" />
          </div>
          {(readyToComplete || balance) && (
            <Button
              text={translate("payment.action.completeButton", { amount: totalAmountToPay })}
              errorText={errorAddPaymentEnd?.error || error}
              disabled={pending || !readyToComplete}
              onClick={handleCreateWorkstation}
              block
            />
          )}
          {showReferralBox && referreReward && (
            <div className="referral-box">
              <img src={VagonGift} />
              {translate("payment.content.referralMessage", { referreReward })}
            </div>
          )}
        </div>
      </div>
      {showPayment3DSecure && (
        <Payment3DSecureModal
          {...props}
          onSuccess={() => {
            setShowPayment3DSecure(false);
          }}
          onFailure={handle3DSecureFailure}
          setShowPayment3DSecure={setShowPayment3DSecure}
        />
      )}
    </div>
  );
};

export default CreateComputerPayment;
