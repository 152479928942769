const ORGANIZATION_API_ENDPOINTS = {
  ORGANIZATION_INVITATION_MINE: "/organizations/invitations/mine/current",
  ACCEPT_ORGANIZATION_INVITATION: "/organizations/invitations/accept",
  REJECT_ORGANIZATION_INVITATION: "/organizations/invitations/reject",
  ORGANIZATION: "/organizations/me",
  ORGANIZATION_PLAN: "/organizations/me/plan",
  ORGANIZATIONS: "/organizations",
  ORGANIZATIONS_REGISTER: "/organizations/register",
  ORGANIZATION_INVITATIONS_BULK: "/organizations/invitations/bulk-create",
  ORGANIZATION_INVITATIONS: "/organizations/invitations",
  ORGANIZATION_INVITATIONS_CHECK: "/organizations/invitations/check",
  ORGANIZATION_INVITATION: (id) => `/organizations/invitations/${id}`,
  MY_ORGANIZATION_INVITATION: "/organizations/invitations/mine/current",
  ORGANIZATION_OBJECTS: "/organizations/me/objects",
  ORGANIZATION_CALCULATE_SHARED_FILE_STORAGE: "/organizations/calculate-shared-file-storage-price",
  ORGANIZATION_UPGRADE_SHARED_FILE_STORAGE: "/organizations/upgrade-shared-file-storage",
  ORGANIZATION_SEAT_PLAN: (id) => `/organizations/seat-plans/${id}`,
  ORGANIZATION_SEAT_PLANS: "/organizations/seat-plans",
  ORGANIZATION_SEAT_PLANS_NEXT_PLAN_NAME: "/organizations/seat-plans/next-plan-name",
  ORGANIZATION_SEAT_PLANS_CALCULATE: "/organizations/seat-plans/calculate",
  ORGANIZATION_SEAT_PLANS_MACHINE_TYPES: (id) => `/organizations/seat-plans/${id}/machine-types`,
  ORGANIZATION_SEATS_CALCULATE: "/organizations/seats/calculate",
  ORGANIZATION_SEATS: "/organizations/seats",
  ORGANIZATIONS_SEATS_IMAGE_ASSIGNABLE: "/organizations/seats/image-assignable",
  ORGANIZATIONS_SEATS_IMAGE_CREATABLE: "/organizations/seats/image-creatable",
  ORGANIZATION_SEATS_COMPLETE: "/organizations/seats/complete-payment",
  ORGANIZATION_BILLING_DETAILS_DEFAULT: "/organizations/billing-details/default",
  ORGANIZATION_SEATS_ASSIGNABLE: "/organizations/seats/assignable",
  ORGANIZATION_SEATS_ASSIGN: (id) => `/organizations/seats/${id}/assign`,
  ORGANIZATION_SEATS_RENEWAL: (id) => `/organizations/seats/${id}/renewal`,
  ORGANIZATION_SEATS_REVOKE_BALANCE: (id) => `/organizations/seats/${id}/revoke-balance`,
  ORGANIZATION_SEATS_TOTAL_NETWORK_USAGE: (id) => `/organizations/seats/${id}/total-network-usage`,
  ORGANIZATION_USERS_ASSIGNABLE: "/organizations/users/assignable",
  ORGANIZATION_USERS_ASSIGN: "/organizations/users/assign",
  ORGANIZATION_USER: (id) => `/organizations/users/${id}`,
  ORGANIZATIONS_SEAT: (id) => `/organizations/seats/${id}`,
  ORGANIZATION_SEATS_REVOKE: (id) => `/organizations/seats/${id}/revoke`,
  ORGANIZATION_SEATS_SWITCH: (id) => `/organizations/seats/${id}/switch`,
  ORGANIZATION_SEATS_ME: "/organizations/seats/me",
  ORGANIZATION_SEATS_CALCULATE_DISK_SIZE_PRICE: (id) => `/organizations/seats/${id}/calculate-disk-size-price`,
  ORGANIZATION_SEATS_CALCULATE_FILE_STORAGE_PRICE: (id) => `/organizations/seats/${id}/calculate-file-storage-price`,
  ORGANIZATION_SEATS_UPGRADE_DISK_SIZE: (id) => `/organizations/seats/${id}/upgrade-disk-size`,
  ORGANIZATION_SEATS_UPGRADE_FILE_STORAGE: (id) => `/organizations/seats/${id}/upgrade-file-storage`,
  ORGANIZATION_SEATS_CALCULATE_ADDITIONAL_USAGE_PRICE: (id) =>
    `/organizations/seats/${id}/calculate-additional-usage-price`,
  ORGANIZATION_SEATS_DEPOSIT_ADDITIONAL_USAGE: (id) => `/organizations/seats/${id}/deposit-additional-usage`,
  ORGANIZATION_SEATS_PERMISSIONS: (id) => `/organizations/seats/${id}/permissions`,
  ORGANIZATION_SEATS_ALL_PERMISSIONS: "/organizations/seats/permissions",
  ORGANIZATION_MACHINES: (id) => `/organizations/machines/${id}`,
  ORGANIZATION_PUBLIC_MACHINES: (uid) => `/organizations/external-access/${uid}`,
  ORGANIZATIONS_MACHINES_REGION: (id) => `/organizations/machines/${id}/region`,
  ORGANIZATIONS_MACHINES_MACHINE_TYPE: (id) => `/organizations/machines/${id}/machine-type`,
  ORGANIZATIONS_MACHINES_START: (id) => `/organizations/machines/${id}/start`,
  ORGANIZATIONS_MACHINES_STOP: (id) => `/organizations/machines/${id}/stop`,
  ORGANIZATIONS_MACHINES_TAKE_SCREENSHOT: (id) => `/organizations/machines/${id}/screenshot.json`,
  ORGANIZATIONS_MACHINES_RUN_SCRIPT: (id) => `/organizations/machines/${id}/run-script`,
  ORGANIZATIONS_SILVER_IMAGE: (id) => `/organizations/silver-images/${id}`,
  ORGANIZATIONS_SILVER_IMAGES: "/organizations/silver-images",
  ORGANIZATIONS_SILVER_IMAGES_INSTALL: "/organizations/silver-images/install",
  ORGANIZATIONS_SILVER_IMAGES_ASSIGN: (id) => `/organizations/silver-images/${id}/assign`,
  ORGANIZATION_PAYMENTS: "/organizations/payments",
  ORGANIZATION_FAILED_PAYMENT: "/organizations/payments/failed-payment",
  ORGANIZATION_MACHINE_SESSIONS: "/organizations/machine-sessions",
  ORGANIZATION_MACHINE_SESSIONS_MINE: "/organizations/machine-sessions/mine",
  ORGANIZATION_MACHINE_SESSIONS_STATS: "/organizations/machine-sessions/stats",
  ORGANIZATION_MACHINE_SESSIONS_USAGE_STATS: "/organizations/machine-sessions/usage-stats",
  ORGANIZATION_MACHINE_SESSIONS_USAGE_STAT_FILTERS: "/organizations/machine-sessions/usage-stat-filters",
  ORGANIZATION_MACHINE_SESSIONS_FILTER_OPTIONS: "/organizations/machine-sessions/filter-options",
  ORGANIZATION_TRANSACTIONS: "/organizations/me/transactions",
  ORGANIZATION_SUBSCRIPTION: "/organizations/me/subscription",
  ORGANIZATION_SUBSCRIPTION_RENEWAL: "/organizations/me/subscription-renewal",
  ORGANIZATION_PAYMENT_INVOICE: (id) => `/organizations/payments/${id}/invoice`,
  ORGANIZATION_ACTIVITY_WATCHER_LOGS_STATS_URLS: "/organizations/activity-watcher-logs/stats-urls",
  ORGANIZATION_API_SECRET: "/organizations/api-secrets/default",
};

export const API_ENDPOINTS = {
  ...ORGANIZATION_API_ENDPOINTS,
  REGISTER: "/users/",
  VERIFY_REGISTER: "/users/registration/verify",
  RESET_PASSWORD: "/reset-password/",
  FORGET_PASSWORD_RESET: (code) => `/reset-password/${code}/`,
  LOGIN: "/oauth/token",
  LOGOUT: "/oauth/revoke",
  ACCOUNT: "/users/me",
  ACCOUNT_SCOPE: "/users/me/scope",
  ACCOUNT_PROFILE: "/users/me/profile",
  ACCOUNT_PREDEFINED: "/users/me/predefined-plan",
  APPLY_PROMOCODE: "/users/me/promo-code",
  ACCOUNT_UNLINK_GOOGLE: "/users/me/unlink-google",
  CHANGE_PASSWORD: "/users/change-password",
  REWARDS_STATUS: "/rewards/status",
  REWARDS_USER_STATUS: "/rewards/user-status",
  REWARDS_CLAIM: "/rewards/claim",
  REWARDS_INVITE: "/rewards/invite",
  PLANS: "/plans/default",
  REGIONS: "/regions",
  SUBSCRIPTIONS: "/subscriptions",
  SUBSCRIPTIONS_COMPLETE: "/subscriptions/complete-payment",
  GET_PAYMENT_INFORMATION: "/subscriptions/payment-information",
  UNSUBSCRIBE: "/subscriptions/unsubscribe",
  RENEW_SUBSCRIPTION: "/subscriptions/renew",
  UPGRADE_DISK_SIZE_SUBSCRIPTION: "/subscriptions/disk",
  UPGRADE_FILE_STORAGE_SUBSCRIPTION_API: "/subscriptions/storage",
  ACCOUNT_USER_IMAGE_UPLOAD: "/users/me/profile-picture-upload-url",
  ACCOUNT_USER_IMAGE_DELETE: "/users/me/profile-picture",
  ACCOUNT_USER_IMAGE_UPLOAD_COMPLETED: "/users/me/profile-picture-upload-complete",
  GET_FILE_STORAGE_SUBSCRIPTION_PLANS: "/subscriptions/available-storage-capacities",
  UPGRADE_DISK_SIZE_SUBSCRIPTION_COMPLETE: "/subscriptions/complete-disk-payment",
  SOFTWARES: "/softwares",
  INSTALL_SOFTWARES: "/softwares/install",
  VALIDATE_SOFTWARES: "/softwares/validate",
  GET_USER_PAYMENT_METHODS: "/payment-methods",
  SET_PREFERRED_PAYMENT_METHOD: (methodID) => `/payment-methods/${methodID}/preferred`,
  GET_PAYMENT_CLIENT_SECRET: "/payment-methods/client-secret",
  CREATE_USER_PAYMENT_METHOD: "/payment-methods",
  DELETE_USER_PAYMENT_METHOD: (methodID) => `/payment-methods/${methodID}`,
  APPROVE_USER_PAYMENT_METHOD: "/payment-methods/preferred/approve-consent",
  DENY_USER_PAYMENT_METHOD: "/payment-methods/preferred/deny-consent",
  WORKSTATIONS: "/users/workstations",
  WORKSTATION_RESET: "/users/workstations/reset",
  WORKSTATION_CHANGE_REGION: "/users/workstations/change-region",
  WORKSTATION_CHANGE_REGION_PRICE: "/users/workstations/change-region-price",
  ADD_CONNECT_TIME: "/users/workstations/add-connect-time",
  CURRENT_SESSION: "/users/workstations/current-session",
  WORKSTATION_MACHINE_TYPES: "/users/workstations/active-machine-types",
  PAYMENT_HISTORY: "/payments",
  PAYMENT_INVOICE: (id) => `/payments/${id}/invoice`,
  TRANSACTION_HISTORY: "/transactions",
  START_PAYMENT: "/payments/start-payment",
  ADD_BALANCE: "/payments/deposit",
  COMPLETE_PAYMENT: "/payments/complete-payment",
  COMPLETE_ADD_BALANCE: "/payments/complete-deposit",
  FAILED_PAYMENT: "/payments/failed-payment",
  MACHINE_USAGE_HISTORY: "/machine-usages",
  NOTIFICATIONS: "/notifications",
  READ_NOTIFICATION: (id) => `/notifications/${id}/read`,
  RESEND_CONFIRMATION: "/users/registration/resend",
  CREATE_WORKSTATION_FILE: "/files",
  WORKSTATION_FILE_UPLOAD_COMPLETED: (id) => `/files/${id}/complete`,
  SESSION_FEEDBACK: "feedbacks/session",
  SUBSCRIPTION_FEEDBACK: "feedbacks/subscription-end",
  GET_USER_FILES: "/file-manager/files",
  GET_FILE_STAT: "file-manager/files/stat",
  GET_RECENT_FILES: "/file-manager/files/recent",
  GET_FILE_STORAGE_CAPACITY: "/file-manager/files/capacity",
  GET_PRODUCT_ONBOARDING: "users/me/product-onboarding-states",
  TIME_ZONE: "users/me/time-zone",
  MODIFY_USER_FILES: (id) => `/file-manager/files/${id}`,
  CREATE_FILE: "/file-manager/files",
  SYNC_FILE: (id) => `/file-manager/files/${id}/sync`,
  DOWNLOAD_FILE: (id) => `/file-manager/files/${id}/download`,
  UPLOAD_COMPLETED: (id) => `/file-manager/files/${id}/complete`,
  APP_STREAMING_SESSION: (id) => `/app-stream-sessions/${id}`,
  APP_STREAMING_SESSION_METRICS: "/app-stream-sessions/metrics",
  APP_STREAMING_SESSION_ASSETS: "/app-stream-sessions/assets",
  IP_LOCATION: "https://ipapi.co/json/",
  CHECK_FINGERPRINT: "/verifications/check-fingerprint",
  VERIFY_FINGERPRINT: "/verifications/verify-fingerprint",
  HEALTH_CHECK: "/healthcheck",
};

export const APP_STREAMING_API_ENDPOINTS = {
  SECRETS: "/vendor/api-secrets/default",
  REGISTER: "/vendor/register",
  APPLICATIONS: "/vendor/applications",
  ACTIVE_APPLICATIONS: "/vendor/applications/active",
  BUNDLE_ABLE_APPLICATIONS: "/vendor/applications/bundleable",
  APPLICATIONS_STATS: "/vendor/applications/stats",
  APPLICATION: (appID) => `/vendor/applications/${appID}`,
  DELETE_APP: (appID) => `/vendor/applications/${appID}`,
  DELETE_APP_BANNER: (appID) => `/vendor/applications/${appID}/banner`,
  DELETE_APP_LOGO: (appID) => `/vendor/applications/${appID}/logo`,
  CONFIGURE_APPLICATION: (appID) => `/vendor/applications/${appID}/configure`,
  APPLICATION_STATUS: (appID) => `/vendor/applications/${appID}/stats`,
  CUSTOMER_SESSION_STATS: (appID) => `/vendor/applications/${appID}/customer-session-stats`,
  GET_VENDOR_APPLICATIONS_STATS: "vendor/applications/stats",
  APPLICATIONS_CREATE_BUNDLED: "/vendor/applications/create-bundled",
  TEST_APP: (appID) => `/vendor/applications/${appID}/test`,
  VENDOR_ACCOUNT: "/vendor/me",
  VENDOR: "/vendor",
  CAPACITY_WARNING_CONSENT: "/vendor/me/capacity-warning-consent",
  GET_CURRENT_PLAN: "/vendor/current-plan",
  GET_REGIONS: "/vendor/regions",
  GET_VENDOR_BALANCE: "/vendor/me/balance",
  GET_VENDOR_BALANCE_STATS: "/vendor/me/balance/stats",
  GET_VENDOR_PAYMENTS: "/vendor/payments",
  GET_VENDOR_PAYMENT_INVOICE: (id) => `/vendor/payments/${id}/invoice`,
  GET_VENDOR_CHARGE_DETAILS: "/vendor/me/balance/transactions",
  GET_VENDOR_TRANSACTIONS: "/vendor/me/balance/deposits",
  GET_VENDOR_TRANSACTIONS_FILTER_OPTIONS: "/vendor/me/balance/transaction-filter-options",
  CALCULATE_REGION_PRICING: "/vendor/streams/calculate-region-pricing",
  GET_APPLICATION_IMAGES: (appID) => `/vendor/applications/${appID}/images`,
  GET_APPLICATION_CHECK_BOOST: (appID) => `/vendor/applications/${appID}/check-boost`,
  GET_APPLICATION_MACHINE_TYPES: (appID) => `/vendor/applications/${appID}/machine-type-pricing`,
  UPDATE_APP_MACHINE_TYPE: (appID) => `/vendor/applications/${appID}/machine-type`,
  GET_CUSTOMER_STATS: "/vendor/customers/stats",
  GET_CUSTOMER_SESSION_DETAILS: "/vendor/customers/sessions",
  CHANGE_REQUESTS: "/vendor/change-requests",
  APPLICATION_UPLOAD: "/vendor/applications",
  APPLICATION_FILE_UPLOAD_COMPLETED: (id) => `/vendor/applications/${id}/complete`,
  APPLICATION_VERSION_UPLOAD: "/vendor/executables",
  APPLICATION_VERSION_UPLOAD_COMPLETED: (id) => `/vendor/executables/${id}/complete`,
  CONFIGURE_EXECUTABLE: (id) => `/vendor/executables/${id}/configure`,
  UPDATE_EXECUTABLE: (id) => `/vendor/executables/${id}`,
  APPLICATION_EXECUTABLES: (id) => `/vendor/applications/${id}/executables`,
  UPDATE_ACTIVE_EXECUTABLE: (id) => `/vendor/executables/${id}/activate`,
  DELETE_EXECUTABLE: (id) => `/vendor/executables/${id}`,
  GET_ALL_STREAMS: "/vendor/streams/all",
  CREATE_STREAM: "/vendor/streams",
  DELETE_STREAM: (appID) => `/vendor/streams/${appID}`,
  UPDATE_STREAM: (streamID) => `/vendor/streams/${streamID}`,
  STREAM_TEXTS: (streamID) => `/vendor/streams/${streamID}/texts`,
  GET_STREAM_CAPACITIES: (streamID) => `/vendor/streams/${streamID}/capacities`,
  PAUSE_STREAM: (streamID) => `/vendor/streams/${streamID}/pause`,
  START_STREAM: (streamID) => `/vendor/streams/${streamID}/start`,
  GET_APPLICATION_CAPACITIES: (appID) => `/vendor/applications/${appID}/capacities`,
  GET_BUDGET_APPLICATION_LIMIT: (appID) => `/vendor/applications/${appID}/budget`,
  APPLICATION_LOGO_UPLOAD: (appID) => `/vendor/applications/${appID}/logo`,
  APPLICATION_BANNER_UPLOAD: (appID) => `/vendor/applications/${appID}/banner`,
  GET_MACHINE_STATS: "/vendor/machines/stats", // to display data on graph part of stats page
  GET_MACHINES: "/vendor/machines",
  GET_MACHINES_FILTER_OPTIONS: "/vendor/machines/filter-options",
  UPDATE_VENDOR_BILLING_INFO: "/vendor/me/billing-information",
  SET_LAUNCH_ARGUMENTS: (streamID) => `/vendor/streams/${streamID}/arguments`,
  LIMIT_SESSION_DURATION: (streamID) => `/vendor/streams/${streamID}/session-duration`,
  GET_STREAM: (streamID) => `/vendor/streams/${streamID}`,
  UPDATE_STREAM_LINK: (streamID) => `/vendor/streams/${streamID}/link`,
  UPDATE_STREAM_NAME: (streamID) => `/vendor/streams/${streamID}/name`,
  GET_VENDOR_ADMINS: "/vendor/admins",
  GET_VENDOR_ADMIN_INVITATIONS: "/vendor/admin-invitations",
  CREATE_VENDOR_ADMIN_INVITATION: "/vendor/admin-invitations",
  DELETE_VENDOR_ADMIN_INVITATION: (id) => `/vendor/admin-invitations/${id}`,
  DELETE_VENDOR_ADMIN: (id) => `/vendor/admins/${id}`,
  STREAM_SESSION_FILE_UPLOAD: "/app-stream-sessions/files",
  STREAM_SESSION_FILE_UPLOAD_COMPLETED: (id) => `/app-stream-sessions/files/${id}/complete`,
  VENDOR_SUBSCRIPTIONS: "/vendor/subscriptions",
  VENDOR_SUBSCRIPTIONS_COMPLETE: "/vendor/subscriptions/complete-payment",
  VENDOR_SUBSCRIPTIONS_DOWNGRADE: "/vendor/subscriptions/downgrade",
  VENDOR_SUBSCRIPTIONS_CALCULATE: "/vendor/subscriptions/calculate",
  VENDOR_SUBSCRIPTIONS_RENEW: "/vendor/subscriptions/renew",
  VENDOR_SUBSCRIPTIONS_UPGRADE: "/vendor/subscriptions/upgrade",
  VENDOR_DEPOSIT_RULES: "/vendor/deposit-rules",
  VENDOR_DEPOSIT_RULE: (id) => `/vendor/deposit-rules/${id}`,
  STREAM_PRELOADED_FILES: (streamID) => `/vendor/streams/${streamID}/preloaded-files`,
  STREAM_PRELOADED_FILE: (streamID, fileId) => `/vendor/streams/${streamID}/preloaded-files/${fileId}`,
  COMPLETE_STREAM_PRELOADED_FILES: (streamID, fileId) =>
    `/vendor/streams/${streamID}/preloaded-files/${fileId}/complete`,
  VENDOR_EXECUTABLE_HELPERS: "/vendor/executable-helpers",
  VENDOR_EXECUTABLE_HELPER: (id) => `/vendor/executable-helpers/${id}`,
};

export const STATUS_TYPE = {
  INFORMATION: 1,
  SUCCESS: 2,
  REDIRECT: 3,
  CLIENT_ERROR: 4,
  SERVER_ERROR: 5,
};
