import React, { useState, useRef, useEffect } from "react";
import { Translate } from "react-localize-redux";
import routes from "Constants/Route.constants";
import { SectionScroll, Icon, Divider, BoxInput, MachineTypeList } from "UI";

import CreateComputerAction from "Components/Dashboard/CreateComputer/Components/CreateComputerAction/CreateComputerAction.component";
import RegularPlanImage from "Assets/images/plans/regular.png";
import { AddBalanceForm } from "Utils";

import { REQUEST_STATUS, LOCAL_STORAGE } from "Constants/global.constants";
import { saveItemToLocalStorage } from "Utils/Helpers/storage.helpers";
import "./CreateComputerSubscription.styles.scss";

const ADD_BALANCE_PRICES = [10, 50, 100, 250];
const MAX_STRING_LENGTH = 600;

const getPlanObject = (getAccountPredefinedCTX) => {
  if (!getAccountPredefinedCTX.data) {
    return {};
  }
  return {
    id: getAccountPredefinedCTX.id,
    name: getAccountPredefinedCTX.data.attributes.name,
    trialInterval: getAccountPredefinedCTX.data.attributes.trial_interval,
    price: getAccountPredefinedCTX.data.attributes.price,
    diskSize: getAccountPredefinedCTX.data.attributes.disk_size,
  };
};

const CreateComputerSubscription = (props) => {
  const {
    account,
    balance,
    setBalance,
    onCheckout,
    subscriptions,
    workstationConfig,
    getWorkstationMachineTypesCTX,
    getPlanData,
    getAccountPredefinedAPI,
    applyPromoCodeAPI,
    translate,
    history,
  } = props;

  const {
    amount,
    description,
    trial_enabled: trialEnabled,
    trial_interval: trialInterval,
  } = subscriptions.getUserSubscriptionPaymentInformationCTX.data || {};

  const predefinedPlan = getPlanObject(account.getAccountPredefinedCTX);
  const userFromAwsMarketplace = account.account.attributes?.disable_new_payment_method;

  const trialTime = trialEnabled ? `${trialInterval} ${translate("date.days")}` : translate("date.month");

  const [activeSection, setActiveSection] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeError, setPromoCodeError] = useState("");

  const promoInputRef = useRef(null);
  const promoInputFocusTimeout = useRef(null);

  const showPromoInput = window.location.hash === "#plan-code";
  const requestPending =
    account.getAccountPredefinedCTX.status === REQUEST_STATUS.PENDING ||
    account.applyPromoCodeCTX.status === REQUEST_STATUS.PENDING;

  const { machine_types: machineTypes } = (getWorkstationMachineTypesCTX && getWorkstationMachineTypesCTX.data) || {};

  useEffect(() => {
    getPlanData();
  }, []);

  useEffect(() => {
    if (userFromAwsMarketplace) {
      setBalance(null);
    }
  }, [userFromAwsMarketplace]);

  useEffect(() => {
    if (promoInputRef.current && showPromoInput) {
      promoInputFocusTimeout.current = setTimeout(() => promoInputRef.current.focus(), 1000);
    }

    if (!showPromoInput) {
      setPromoCodeError("");
    }

    return () => {
      clearTimeout(promoInputFocusTimeout.current);
    };
  }, [promoInputRef, showPromoInput]);

  useEffect(() => {
    if (account.applyPromoCodeCTX.status === REQUEST_STATUS.SUCCESS) {
      getAccountPredefinedAPI(account.accessToken);
      getPlanData();
    } else if (account.applyPromoCodeCTX.status === REQUEST_STATUS.FAILURE) {
      setPromoCode("");
      const errorCode = account.applyPromoCodeCTX.data.client_code;
      setPromoCodeError(errorCode);
    }
  }, [account.applyPromoCodeCTX]);

  useEffect(() => {
    if (account.getAccountPredefinedCTX.status === REQUEST_STATUS.SUCCESS) {
      if (showPromoInput) {
        history.goBack();
      }
    }
  }, [account.getAccountPredefinedCTX]);

  const closePromoInput = () => {
    if (showPromoInput && !promoCode) {
      if (!requestPending) {
        history.goBack();
      }
    }
  };

  const onPromoCodeSubmit = () => {
    if (promoCode) {
      applyPromoCodeAPI(account.accessToken, promoCode);
    }
  };

  const onPromoCodeChange = (event) => {
    setPromoCode(event.target.value.toUpperCase());
  };

  const onActionButtonClick = () => {
    saveItemToLocalStorage(LOCAL_STORAGE.createComputerConfigs, { balance });
    onCheckout();
    history.push(routes.createComputerPayment);
  };

  const planDetailsList = [
    {
      id: 1,
      detail: (
        description ||
        translate("subscription.content.plans.details.detail1", { trialInterval: predefinedPlan.trialInterval })
      ).slice(0, MAX_STRING_LENGTH),
    },
    {
      id: 2,
      detail: translate("subscription.content.plans.details.detail2", {
        diskSize: workstationConfig.disk_size,
        fileStorageSize: 5,
      }),
    },
    { id: 3, detail: translate("subscription.content.plans.details.detail3") },
  ];

  return (
    <div className="subscription-container">
      <SectionScroll
        className="connection-content"
        isScrolling={isScrolling}
        setIsScrolling={setIsScrolling}
        setActiveSection={setActiveSection}
      >
        <section className="plan-section" id="status">
          <div className={`plan-container ${showPromoInput ? "promo-view" : ""}`}>
            <div className="plan-info">
              <img className="plan-image" alt="Vagon for Creatives" src={RegularPlanImage} />
              <div className="plan-pricing-wrapper">
                <div className="plan-pricing">
                  <h3>{predefinedPlan.name}</h3>
                  <p>
                    <span className="price">$ {amount || predefinedPlan.price} </span>/ {trialTime}
                  </p>
                  <a href="#plan-code">{translate("subscription.content.promoCode.showInput")}</a>
                </div>
              </div>
            </div>
            <div className="divider" />
            <div className="plan-details">
              <div className="details-wrapper">
                <ul className="details-list">
                  {planDetailsList.map((detailItem) => (
                    <li key={detailItem.id}>{detailItem.detail}</li>
                  ))}
                </ul>
              </div>
              <div className="promo-input">
                <h3>{translate("subscription.content.promoCode.label")}</h3>
                <BoxInput
                  inputRef={promoInputRef}
                  placeholder={translate("subscription.content.promoCode.placeholder")}
                  iconName="arrow-right-small"
                  onBlur={closePromoInput}
                  onSubmit={onPromoCodeSubmit}
                  value={promoCode}
                  onChange={onPromoCodeChange}
                />
                {promoCodeError && (
                  <div className="error-wrapper">
                    <Icon name="error" color="purple-main" />
                    <Translate
                      id={`subscription.content.promoCode.error.${promoCodeError}`}
                      options={{
                        onMissingTranslation: () => translate("subscription.content.promoCode.error.default"),
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <Divider withLogo isLoading={requestPending} />
          {!userFromAwsMarketplace && (
            <div className="add-balance-container">
              <h3>{translate("subscription.content.addBalance.title")}</h3>
              <p>{translate("subscription.content.addBalance.description")}</p>
              <AddBalanceForm
                defaultBalance={balance}
                setBalance={setBalance}
                prices={ADD_BALANCE_PRICES}
                translate={translate}
              />
            </div>
          )}
        </section>
        {machineTypes && (
          <section className="pricing-section" id="status">
            <MachineTypeList machineTypes={machineTypes} displayAll />
          </section>
        )}
      </SectionScroll>
      <CreateComputerAction
        actionContent={
          !isScrolling &&
          machineTypes && (
            <div className="scroll-page">
              <p>{translate(`subscription.content.section.${activeSection === 0 ? "first" : "second"}.scrollText`)}</p>
              <Icon name={activeSection === 0 ? "scroll-down" : "scroll-up"} className="scroll" />
            </div>
          )
        }
        buttonText={translate("subscription.action.button")}
        onButtonClick={onActionButtonClick}
      />
    </div>
  );
};

export default CreateComputerSubscription;
