import {
  LOGIN_CLEAR,
  LOGIN_API_PENDING,
  LOGOUT_API_PENDING,
  CHECK_FINGERPRINT_PENDING,
  VERIFY_FINGERPRINT_PENDING,
} from "Constants/Authentication/Login.constants";
import { LOCAL_STORAGE } from "Constants/global.constants";
import { getItemFromLocalStorage } from "Utils";

export const loginClear = () => ({
  type: LOGIN_CLEAR,
});

export const loginAPI = (loginForm) => {
  const { email, password, recaptchaResponse, assertion, provider, scope, grantType } = loginForm;

  const requestPayload = {
    email: email?.toLowerCase(),
    password,
    grant_type: grantType || "password",
    recaptcha_response: recaptchaResponse,
    assertion,
    provider,
    scope,
  };

  return {
    type: LOGIN_API_PENDING,
    payload: { requestPayload },
  };
};

export const checkFingerprintAPI = ({ fingerprint, fingerprintFromCache = false }) => {
  const requestPayload = {
    fingerprint,
    fingerprint_from_cache: fingerprintFromCache,
    aws_marketplace_token: getItemFromLocalStorage(LOCAL_STORAGE.amzMarketplaceToken),
  };

  return {
    type: CHECK_FINGERPRINT_PENDING,
    payload: { requestPayload },
  };
};

export const verifyFingerprintAPI = (visitorData, verificationCode) => {
  const requestPayload = {
    fingerprint: visitorData?.visitorId,
    fingerprint_response: visitorData,
    verification_code: verificationCode,
    aws_marketplace_token: getItemFromLocalStorage(LOCAL_STORAGE.amzMarketplaceToken),
  };

  return {
    type: VERIFY_FINGERPRINT_PENDING,
    payload: { requestPayload },
  };
};

export const logoutAPI = (accessToken) => {
  const requestPayload = {
    token: accessToken,
  };

  return {
    type: LOGOUT_API_PENDING,
    payload: { accessToken, requestPayload },
  };
};
